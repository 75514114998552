import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Alert from "../component/alert";
import "../assets/css/loginForm.css";

const serverBaseUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [alertData, setAlertData] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const login = async (e) => {
    e.preventDefault();
    try {
      if (!email || !password) {
        setAlertData("Please insert all fields");
        setTimeout(() => setAlertData(false), 3000);
        return;
      }
      setDisabled(true);
      const response = await fetch(`${serverBaseUrl}/admin/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      const responseData = await response.json();
      if (response.status === 200) {
        const admin = responseData.data.admin;
        localStorage.setItem("admin", JSON.stringify(admin));
        localStorage.setItem("token", responseData.data.token);
        navigate("/admin/dashboard");
        return;
      } else {
        setAlertData(responseData?.message || "An error occurred");
        setTimeout(() => setAlertData(false), 3000);
        return;
      }
    } catch (error) {
      setAlertData(`Login error : ${error.message}`);
      setTimeout(() => setAlertData(false), 3000);
      return;
    } finally {
      setDisabled(false);
      return;
    }
  };

  return (
    <>
    {alertData && <Alert message={alertData} type={"error"} />}

      <div className="admin-login-component">
        <form onSubmit={login} className="admin-login-form">
          <div className="icon">
            <FontAwesomeIcon icon={faUser} color="#fff" size="lg" />
          </div>
          <p className="admin-login-heading">Sign In (admin)</p>
          <div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
          </div>
          <div className="admin-password-input">
            <input
              id="password"
              className="input"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="********"
              required
            />
            <div className="icon-div">
              {showPassword ? (
                <BsEye
                  size={18}
                  color="#8d8d8d"
                  onClick={togglePasswordVisibility}
                  className="eye-icon"
                />
              ) : (
                <BsEyeSlash
                  size={18}
                  color="#8d8d8d"
                  onClick={togglePasswordVisibility}
                  className="eye-icon"
                />
              )}
            </div>
          </div>
          <button type="submit" className="login-btn" disabled={disabled}>
            Login
          </button>
        </form>
      </div>
    </>
  );
}

export default Login;
