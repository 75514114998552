import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import LeftComponent from "../component/leftComponent";
import Alert from "../component/alert";

const serverBaseUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

function ResetPassword() {
  const { verificationToken } = useParams();
  const [alertData, setAlertData] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successData, setSuccessData] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const navigate = useNavigate();

  const resetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setAlertData("Passwords do not match");
      setTimeout(() => setAlertData(false), 3000);
      return;
    }
    setFieldErrors({});
    setDisabled(true);
    try {
      const response = await fetch(`${serverBaseUrl}/user/reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "verification-token": verificationToken,
        },
        body: JSON.stringify({
          newPassword,
          confirmPassword,
        }),
      });
      const responseData = await response.json();
      if (response.status === 200) {
        const token = responseData.data.token;
        const user = responseData.data.user;
        localStorage.setItem("user", JSON.stringify({ email: user.email }));
        localStorage.setItem("token", JSON.stringify(token));
        setSuccessData("Password has been updated successfully");
        setTimeout(() => {
          setSuccessData(false);
          navigate("/login");
        }, 3000);
        return;
      } else if (response.status === 400) {
        const error = typeof responseData.error;
        if (error === "object") {
          setFieldErrors(responseData.error);
        } else {
          setAlertData(responseData.message || "An error occurred");
          setTimeout(() => setAlertData(false), 3000);
        }
      } else {
        setAlertData(responseData.message);
        setTimeout(() => setAlertData(false), 3000);
        return;
      }
    } catch (error) {
      setAlertData("Password has not been reset due to some error");
      setTimeout(() => setAlertData(false), 3000);
    } finally {
      setConfirmPassword("");
      setNewPassword("");
      setDisabled(false);
    }
  };

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <>
      {alertData && <Alert message={alertData} type={"error"} />}
      {successData && <Alert message={successData} type={"success"} />}
      <div className="form-page">
        <LeftComponent />
        <div className="right-component">
          <form onSubmit={resetPassword} className="signup-form">
            <h3>Title Registry</h3>
            <h4>Reset Password</h4>
            <div className="input-div">
              <label htmlFor="newPassword">
                New Password <span className="required-asterisk">*</span>
              </label>
              <div className="password-input">
                <input
                  id="newPassword"
                  className="input"
                  type={newPasswordShow ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="********"
                  required
                />
                <button
                  type="button"
                  onClick={() => setNewPasswordShow(!newPasswordShow)}
                  className="icon-div reset-icon-div"
                >
                  {newPasswordShow ? (
                    <BsFillEyeFill
                      size={18}
                      color="#8d8d8d"
                      className="eye-icon"
                    />
                  ) : (
                    <BsFillEyeSlashFill
                      size={18}
                      color="#8d8d8d"
                      className="eye-icon"
                    />
                  )}
                </button>
              </div>
              {fieldErrors.newPassword && (
                <p className="joi-error-message">
                  {fieldErrors.newPassword[0]}
                </p>
              )}
            </div>
            <div className="input-div">
              <label htmlFor="confirmPassword">
                Confirm Password <span className="required-asterisk">*</span>
              </label>
              <div className="password-input">
                <input
                  id="confirmPassword"
                  className="input"
                  type={confirmPasswordShow ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="********"
                  required
                />
                <button
                  type="button"
                  onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                  className="icon-div reset-icon-div"
                >
                  {confirmPasswordShow ? (
                    <BsFillEyeFill
                      size={18}
                      color="#8d8d8d"
                      className="eye-icon"
                    />
                  ) : (
                    <BsFillEyeSlashFill
                      size={18}
                      color="#8d8d8d"
                      className="eye-icon"
                    />
                  )}
                </button>
              </div>
              {fieldErrors.confirmPassword && (
                <p className="joi-error-message">
                  {fieldErrors.confirmPassword[0]}
                </p>
              )}
            </div>

            <button type="submit" className="forgot-btn" disabled={disabled}>
              Reset Password
            </button>
          </form>
          <p className="account-para">
            <span onClick={goToLogin} className="navigate-btn">
              Sign In
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
