import React from "react";
import { Alert as BootstrapAlert } from "react-bootstrap";

const Alert = ({ message, type }) => {
  if (!message) return null;

  return (
    <BootstrapAlert
      className="form-error text-center w-50"
      variant={type === "error" ? "danger" : "success"}
      dismissible
    >
      {message}
    </BootstrapAlert>
  );
};

export default Alert;
