import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "../component/topbar";
import HalfFooter from "../component/half-footer";
import ViewRegistry from "../component/viewRegistry.js";
import Alert from "../component/alert.js";
import "../assets/css/account.css";

const serverBaseUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
  return (
    <div className="pagination">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        &lt;
      </button>
      {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={currentPage === page ? "active" : "in-active"}
        >
          {page}
        </button>
      ))}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        {" "}
        &gt;{" "}
      </button>{" "}
    </div>
  );
};

const Account = () => {
  const limit = 5;
  const pdfRef = useRef(null);
  const [registries, setRegistries] = useState([]);
  const [totalRegistries, setTotalRegistries] = useState(null);
  const [registryDetails, setRegistryDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewRegModalOpen, setViewRegModalOpen] = useState(false);
  const [alertData, setAlertData] = useState(null);
  const [success, setSuccess] = useState(null);
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  const totalPages = Math.ceil(totalRegistries / limit);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paymentStatus = params.get("payment_status");

    if (paymentStatus) {
      if (paymentStatus === "success") {
        setSuccess("Payment completed! Your registry is now active");
        setTimeout(() => setSuccess(null), 3000);
      } else if (paymentStatus === "failure") {
        setAlertData("Payment failed! Please try again");
        setTimeout(() => setAlertData(null), 3000);
      }
      params.delete("payment_status");
      navigate({
        pathname: location.pathname,
        search: params.toString(),
      });
    }
  }, [location]);

  const getRegistries = async (offset, limit) => {
    try {
      if (!token) return;
      const response = await fetch(
        `${serverBaseUrl}/user/registries?offset=${offset}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      const responseData = await response.json();
      if (response.status === 200) {
        setRegistries(responseData.data.registries);
        setTotalRegistries(responseData.data.totalRegistries);
      } else {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(null), 3000);
      }
    } catch (error) {
      setAlertData(error.message || "An error occurred");
      setTimeout(() => setAlertData(null), 3000);
    }
  };

  const getRegistryDetails = async (id) => {
    try {
      const response = await fetch(`${serverBaseUrl}/user/registry?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      const responseData = await response.json();
      if (response.status === 200) {
        setRegistryDetails(responseData.data[0]);
      } else {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(null), 3000);
      }
    } catch (error) {
      setAlertData(error.message || "An error occurred");
      setTimeout(() => setAlertData(null), 3000);
    }
  };

  useEffect(() => {
    getRegistries((currentPage - 1) * limit, limit);
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleViewRegistry = (id) => {
    getRegistryDetails(id);
    setViewRegModalOpen(true);
  };

  const openModal = (url) => {
    setSelectedPdf(url);
    setPdfModalOpen(true);
  };

  const closeModal = () => {
    setPdfModalOpen(false);
    setSelectedPdf(null);
  };

  const handleClickOutside = (event) => {
    if (pdfRef.current && !pdfRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    if (pdfModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [pdfModalOpen]);

  const fileType = (url) => {
    const baseUrl = url.split("?")[0];
    const extension = baseUrl.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp", "tiff"];
    return imageExtensions.includes(extension) ? "image" : "pdf";
  };

  const formatDate = (dateString) => {
    const [datePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");
    return `${month}/${day}/${year}`;
  };

  const payment = (id) => {
    navigate(`/payment?id=${id}`);
  };

  return (
    <>
      {alertData && <Alert message={alertData} type={"error"} />}
      {success && <Alert message={success} type={"success"} />}
      <div className="page account-page">
        <TopBar />
        <div className="page-main-div flex-column">
          <div className="account-table">
            <h2>Account</h2>
            <table>
              <thead>
                <tr>
                  <th>Material Name</th>
                  <th>Material Type</th>
                  <th>Date Submitted</th>
                  <th>Document</th>
                  <th className="action-column">Action</th>
                </tr>
              </thead>
              <tbody>
                {user ? (
                  registries.length < 1 ? (
                    <tr>
                      <td colSpan="5" className="not-found text-center">
                        No Records Found
                      </td>
                    </tr>
                  ) : (
                    registries.map((item, index) => (
                      <tr key={index}>
                        <td>{item.title_item}</td>
                        <td>{item.material_type}</td>
                        <td>{formatDate(item.created_at)}</td>
                        <td>
                          <div
                            style={{
                              position: "relative",
                              width: "80px",
                              height: "50px",
                              cursor: "pointer",
                              overflow: "hidden",
                              backgroundImage:
                                fileType(item.s3_file_url) === "image"
                                  ? `url(${item.s3_file_url})`
                                  : "none",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                            }}
                            onClick={() => openModal(item.s3_file_url)}
                          >
                            {fileType(item.s3_file_url) === "pdf" ? (
                              <iframe
                                src={item.s3_file_url}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  border: "none",
                                  pointerEvents: "none",
                                }}
                              />
                            ) : null}
                          </div>
                        </td>
                        <td className="flex">
                          <button
                            className="view-button"
                            onClick={() => handleViewRegistry(item.id)}
                          >
                            View Material
                          </button>
                          {item.status === "pending" && (
                            <button
                              className="view-button view-button-danger"
                              onClick={() => payment(item.id)}
                            >
                              Pay Now
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  )
                ) : (
                  <tr>
                    <td colSpan="5" className="not-found text-center">
                      Sign In First
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {user && registries.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          )}
        </div>

        <HalfFooter />
      </div>
      {viewRegModalOpen && (
        <ViewRegistry
          isOpen={viewRegModalOpen}
          onClose={() => setViewRegModalOpen(false)}
          registryDetails={registryDetails}
        />
      )}
      {selectedPdf && (
        <div className="modal-overlay d-flex align-items-center justify-content-center">
          <div
            className="model-inside-div"
            style={{
              position: "relative",
              width: "50%",
              height: "70%",
            }}
          >
            {fileType(selectedPdf) === "pdf" ? (
              <iframe
                src={selectedPdf}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                ref={pdfRef}
              />
            ) : (
              <img
                src={selectedPdf}
                alt="Selected File"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                ref={pdfRef}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Account;
