import React, { useEffect, useState } from "react";

const PaginatedTable = ({
  data,
  headers,
  renderRow,
  limit,
  onPageChange,
  totalData,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const totalPages = Math.ceil(totalData / limit);

  useEffect(() => {
    setListData(data);
  }, [data]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      onPageChange((newPage - 1) * limit, limit);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      onPageChange((newPage - 1) * limit, limit);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    onPageChange((page - 1) * limit, limit);
  };

  const currentData = () => {
    return listData?.slice();
  };

  const renderPagination = () => {
    const pages = [];
    const pageRange = 1;

    for (let i = currentPage - pageRange; i <= currentPage + pageRange; i++) {
      if (i < 1 || i > totalPages) continue;

      pages.push(
        <li
          key={i}
          className={`page-item ${
            i === currentPage ? "pagination-active" : ""
          }`}
        >
          <button className="page-link" onClick={() => handlePageClick(i)}>
            {i}
          </button>
        </li>
      );
    }

    return pages;
  };

  const columnWidth = headers ? `${100 / headers.length}%` : "auto";

  return (
    <div className="project-tables-container d-flex flex-column justify-content-between">
      <div style={{ overflowX: "auto" }} className="project-table-scroll">
        <table className="project-table">
          <thead>
            <tr>
              {headers?.map((header, index) => (
                <th key={index} style={{ width: columnWidth }}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {totalData === null ? (
              <tr>
                <td colSpan={headers.length} className="not-found">
                  loading...
                </td>
              </tr>
            ) : totalData < 1 ? (
              <tr>
                <td colSpan={headers.length} className="not-found">
                  No Records Found
                </td>
              </tr>
            ) : (
              currentData()?.map(renderRow)
            )}
          </tbody>
        </table>
      </div>
      {totalData > 0 && (
        <div className="pagination-controls">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  onClick={handlePreviousPage}
                  aria-label="Previous"
                  disabled={currentPage === 1}
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              {renderPagination()}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  onClick={handleNextPage}
                  aria-label="Next"
                  disabled={currentPage === totalPages}
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default PaginatedTable;
