import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { intendedMediums, materialOptions } from "../data/intendedData";
import Alert from "./alert";
import countryData from "../data/countries-state-cities.json";

const serverBaseUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

const RegistryForm = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const guest = JSON.parse(localStorage.getItem("guest"));
  const [stateData, setStateData] = useState("");
  const [cityData, setCityData] = useState("");
  const [intendedMedium, setIntendedMedium] = useState("");
  const [materialType, setMaterialType] = useState("");
  const [titleItem, setTitleItem] = useState("");
  const [socialSecurityNumber, setSocialSecurityNumber] = useState("");
  const [identification, setIdentification] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState(user?.email ? user.email : guest?.email);
  const [phoneNumber, setphoneNumber] = useState("");
  const [documentTitle, setDocumentTitle] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [documentFile, setDocumentFile] = useState(null);
  const [alertData, setAlertData] = useState(false);
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const intendedMediumRef = useRef();
  const materialTypeRef = useRef();
  const titleItemRef = useRef();
  const socialSecurityNumberRef = useRef();
  const identificationRef = useRef();
  const firstNameRef = useRef();
  const middleNameRef = useRef();
  const lastNameRef = useRef();
  const countryRef = useRef();
  const provinceRef = useRef();
  const cityRef = useRef();
  const zipRef = useRef();
  const emailRef = useRef();
  const phoneNumberRef = useRef();
  const documentTitleRef = useRef();
  const documentDescriptionRef = useRef();
  const documentFileRef = useRef();

  const refs = {
    intendedMedium: intendedMediumRef,
    materialType: materialTypeRef,
    titleItem: titleItemRef,
    socialSecurityNumber: socialSecurityNumberRef,
    identification: identificationRef,
    firstName: firstNameRef,
    middleName: middleNameRef,
    lastName: lastNameRef,
    country: countryRef,
    province: provinceRef,
    city: cityRef,
    zip: zipRef,
    email: emailRef,
    phoneNumber: phoneNumberRef,
    documentTitle: documentTitleRef,
    documentDescription: documentDescriptionRef,
    documentFile: documentFileRef,
  };

  const findCountryData = (countryName) => {
    return countryData.find((country) => country.name === countryName);
  };

  useEffect(() => {
    if (country) {
      const countryObj = findCountryData(country);
      if (countryObj && countryObj.states) {
        const states = countryObj.states.map((state) => ({
          label: state.name,
          value: state.name,
        }));
        setStateData(states);
      } else {
        setStateData([]);
      }
    }
  }, [country]);

  useEffect(() => {
    if (province && country) {
      const countryObj = findCountryData(country);
      const selectedState = countryObj?.states.find(
        (state) => state.name === province
      );
      if (selectedState && selectedState.cities) {
        const cities = selectedState.cities.map((city) => ({
          label: city.name,
          value: city.name,
        }));
        setCityData(cities);
      } else {
        setCityData([]);
      }
    }
  }, [province, country]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "50px",
      boxShadow: "none",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      overflow: "hidden",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px",
    }),
    input: (provided) => ({
      ...provided,
      padding: "0px",
    }),
    option: (provided, { isSelected, isFocused }) => ({
      ...provided,
      color: "#000000",
      backgroundColor: isSelected ? "#dcdcdc" : isFocused ? "#f0f0f0" : "#fff",
    }),
  };

  useEffect(() => {
    const errorFields = Object.keys(fieldErrors);
    if (errorFields.length > 0) {
      const firstErrorField = errorFields[0];
      if (refs[firstErrorField] && refs[firstErrorField].current) {
        refs[firstErrorField].current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [fieldErrors]);

  const handleRegistration = async (e) => {
    if (!token) {
      localStorage.clear();
      localStorage.setItem("redirected_url", window.location.pathname);
      setAlertData("Please sign in first");
      setTimeout(() => {
        setAlertData(null);
        navigate("/login");
      }, 3000);
      return;
    }
    e.preventDefault();
    try {
      setDisabled(true);
      setLoading(true);
      setFieldErrors({});
      const formData = new FormData();
      formData.append("intendedMedium", intendedMedium);
      formData.append("materialType", materialType);
      formData.append("titleItem", titleItem);
      formData.append("socialSecurityNumber", socialSecurityNumber);
      formData.append("identification", identification);
      formData.append("firstName", firstName);
      formData.append("middleName", middleName);
      formData.append("lastName", lastName);
      formData.append("country", String(country));
      formData.append("province", province);
      formData.append("city", city);
      formData.append("zip", zip);
      formData.append("email", email);
      formData.append("phoneNumber", phoneNumber);
      formData.append("documentTitle", documentTitle);
      formData.append("documentDescription", documentDescription);
      formData.append("document", documentFile);

      const response = await fetch(`${serverBaseUrl}/registry/`, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: formData,
      });
      const responseData = await response.json();
      if (response.status === 201) {
        setSuccess(responseData.message);
        const data = responseData?.data;
        const status = data?.status;
        const id = data?.registryId;
        if (status && status === "active") {
          setTimeout(() => {
            setSuccess(false);
            navigate("/registries");
          }, 3000);
        } else {
          setTimeout(() => {
            setSuccess(false);
            id ? navigate(`/payment?id=${id}`) : navigate("/payment");
          }, 3000);
        }
        return;
      } else if (response.status === 400) {
        const error = typeof responseData.error;
        if (error === "object") {
          setFieldErrors(responseData.error);
        } else {
          setAlertData(responseData.message || "An error occurred");
          setTimeout(() => setAlertData(false), 3000);
        }
      } else {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(false), 3000);
      }
    } catch (error) {
      setAlertData(error);
      setTimeout(() => setAlertData(false), 3000);
      return;
    } finally {
      setDisabled(false);
      setLoading(false);
      return;
    }
  };

  const handleFileChange = (e) => {
    setDocumentFile(e.target.files[0]);
  };

  const handleButtonClick = () => {
    if (documentFileRef.current) {
      documentFileRef.current.click();
    }
  };

  return (
    <>
      {alertData && <Alert message={alertData} type={"error"} />}
      {success && <Alert message={success} type={"success"} />}
      <form onSubmit={handleRegistration} className="signup-form">
        <h2>Register Material</h2>
        <h4>Input Title Name, Material Type, and Intended Medium.</h4>

        <div className="material-fields">
          <div className="flex top-margin">
            <div className="registry-input-div half-div">
              <label htmlFor="intended-medium">
                Intended Medium <span className="required-asterisk">*</span>
              </label>
              <Select
                ref={intendedMediumRef}
                options={intendedMediums}
                className="dropdown"
                styles={customStyles}
                onChange={(selectedOption) =>
                  setIntendedMedium(selectedOption.value)
                }
                menuPlacement="auto"
                required
              />
              {fieldErrors.intendedMediums && (
                <p className="joi-error-message">
                  {fieldErrors.intendedMediums[0]}
                </p>
              )}
            </div>
            <div className="registry-input-div half-div">
              <label htmlFor="material-type">
                Material Type <span className="required-asterisk">*</span>
              </label>
              <Select
                ref={materialTypeRef}
                options={materialOptions}
                className="dropdown"
                styles={customStyles}
                onChange={(selectedOption) =>
                  setMaterialType(selectedOption.value)
                }
                menuPlacement="auto"
                required
              />
              {fieldErrors.materialType && (
                <p className="joi-error-message">
                  {fieldErrors.materialType[0]}
                </p>
              )}
            </div>
          </div>
          <div className="registry-input-div top-margin">
            <label htmlFor="title-item">
              Title of Item <span className="required-asterisk">*</span>
            </label>
            <input
              ref={titleItemRef}
              id="title-item"
              type="text"
              value={titleItem}
              onChange={(e) => setTitleItem(e.target.value)}
              placeholder="Text Here"
              required
            />
            {fieldErrors.titleItem && (
              <p className="joi-error-message">{fieldErrors.titleItem[0]}</p>
            )}
          </div>
        </div>

        <h5>
          The Following personal information items apply to the registrant
        </h5>
        <p className="main-para">Input Registrar Information.</p>
        <div className="personal-information">
          <div className="flex top-margin">
            <div className="registry-input-div half-div joi-error-absolute-div">
              <label htmlFor="social-security-number">
                Social Security Number
                <span className="required-asterisk">*</span>
              </label>
              <input
                ref={socialSecurityNumberRef}
                id="social-security-number"
                type="text"
                value={socialSecurityNumber}
                onChange={(e) => setSocialSecurityNumber(e.target.value)}
                placeholder="XXX-XX-XXXX"
                required
              />
              {fieldErrors.socialSecurityNumber && (
                <p className="joi-error-message joi-error-absolute">
                  {fieldErrors.socialSecurityNumber[0]}
                </p>
              )}
            </div>
            <div className="registry-input-div half-div joi-error-absolute-div">
              <label htmlFor="identification">
                Identification <span className="required-asterisk">*</span>
              </label>
              <input
                ref={identificationRef}
                id="identification"
                type="text"
                value={identification}
                onChange={(e) => setIdentification(e.target.value)}
                placeholder="Text Here"
                required
              />
              {fieldErrors.identification && (
                <p className="joi-error-message joi-error-absolute">
                  {fieldErrors.identification[0]}
                </p>
              )}
            </div>
          </div>

          <div className="flex top-margin">
            <div className="registry-input-div one-third-div joi-error-absolute-div">
              <label htmlFor="first-name">
                First Name
                <span className="required-asterisk">*</span>
              </label>
              <input
                ref={firstNameRef}
                id="first-name"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Text Here"
                required
              />
              {fieldErrors.firstName && (
                <p className="joi-error-message joi-error-absolute">
                  {fieldErrors.firstName[0]}
                </p>
              )}
            </div>
            <div className="registry-input-div one-third-div joi-error-absolute-div">
              <label htmlFor="middle-name">Middle Name</label>
              <input
                ref={middleNameRef}
                id="middle-name"
                type="text"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                placeholder="Text Here"
              />
              {fieldErrors.middleName && (
                <p className="joi-error-message joi-error-absolute">
                  {fieldErrors.middleName[0]}
                </p>
              )}
            </div>
            <div className="registry-input-div one-third-div joi-error-absolute-div">
              <label htmlFor="last-name">
                Last Name <span className="required-asterisk">*</span>
              </label>
              <input
                ref={lastNameRef}
                id="last-name"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Text Here"
                required
              />
              {fieldErrors.lastName && (
                <p className="joi-error-message joi-error-absolute">
                  {fieldErrors.lastName[0]}
                </p>
              )}
            </div>
          </div>

          <div className="flex top-margin">
            <div className="registry-input-div half-div">
              <label htmlFor="country" id="country" ref={countryRef}>
                Country
                <span className="required-asterisk">*</span>
              </label>
              <Select
                options={countryData.map((country) => ({
                  value: country.name,
                  label: country.name,
                }))}
                value={country.name}
                onChange={(selectedOption) => {
                  setCountry(selectedOption.value);
                  setProvince("");
                  setCity("");
                }}
                className="dropdown"
                styles={customStyles}
                menuPlacement="auto"
                placeholder="Select Country"
                required
              />
              {fieldErrors.country && (
                <p className="joi-error-message">{fieldErrors.country[0]}</p>
              )}
            </div>
            <div className="registry-input-div half-div">
              <label htmlFor="state" id="state" ref={provinceRef}>
                State/Province <span className="required-asterisk">*</span>
              </label>
              <Select
                options={stateData}
                value={{ value: province, label: province }}
                onChange={(selectedOption) => {
                  setProvince(selectedOption.value);
                  setCity("");
                }}
                className="dropdown"
                styles={customStyles}
                menuPlacement="auto"
                placeholder="Select State"
                isDisabled={!country}
                required
              />
              {fieldErrors.province && (
                <p className="joi-error-message">{fieldErrors.province[0]}</p>
              )}
            </div>
          </div>

          <div className="flex top-margin">
            <div className="registry-input-div half-div">
              <label htmlFor="city" id="city" ref={cityRef}>
                City <span className="required-asterisk">*</span>
              </label>
              <Select
                options={cityData}
                value={{ value: city, label: city }}
                onChange={(option) => setCity(option.value)}
                className="dropdown city-dropdown"
                styles={customStyles}
                placeholder="Select City"
                isDisabled={!province}
              />
              {fieldErrors.city && (
                <p className="joi-error-message">{fieldErrors.city[0]}</p>
              )}
            </div>
            <div className="registry-input-div half-div">
              <label htmlFor="zip">
                Zip
                <span className="required-asterisk">*</span>
              </label>
              <input
                ref={zipRef}
                id="zip"
                type="text"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                placeholder="Text Here"
                required
              />
              {fieldErrors.zip && (
                <p className="joi-error-message">{fieldErrors.zip[0]}</p>
              )}
            </div>
          </div>

          <div className="registry-input-div top-margin">
            <label htmlFor="phone-number">Phone Number</label>
            <input
              ref={phoneNumberRef}
              id="phone-number"
              type="text"
              value={phoneNumber}
              onChange={(e) => setphoneNumber(e.target.value)}
              placeholder="+14175555470"
              maxLength="12"
            />
            {fieldErrors.phoneNumber && (
              <p className="joi-error-message">{fieldErrors.phoneNumber[0]}</p>
            )}
          </div>
          <div className="registry-input-div top-margin">
            <label htmlFor="email">
              Email <span className="required-asterisk">*</span>
            </label>
            <input
              ref={emailRef}
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="example@email.com"
              disabled={guest ? false : true}
              required
            />
            {fieldErrors.email && (
              <p className="joi-error-message">{fieldErrors.email[0]}</p>
            )}
          </div>
        </div>

        <h2 className="mt-3">Document</h2>
        <h4>Add document title, description and your cv</h4>

        <div className="material-fields">
          <div className="flex top-margin">
            <div className="registry-input-div half-div">
              <label htmlFor="intended-medium">
                Title <span className="required-asterisk">*</span>
              </label>
              <input
                ref={documentTitleRef}
                type="text"
                name="title"
                value={documentTitle}
                onChange={(e) => setDocumentTitle(e.target.value)}
                placeholder="Text here"
                required
              />
              {fieldErrors.documentTitle && (
                <p className="joi-error-message">
                  {fieldErrors.documentTitle[0]}
                </p>
              )}
            </div>
            <div className="registry-input-div half-div">
              <label htmlFor="material-type">
                Description <span className="required-asterisk">*</span>
              </label>
              <textarea
                ref={documentDescriptionRef}
                name="description"
                value={documentDescription}
                onChange={(e) => setDocumentDescription(e.target.value)}
                className="bg-transparent"
                placeholder="Text here"
                required
              />
              {fieldErrors.documentDescription && (
                <p className="joi-error-message">
                  {fieldErrors.documentDescription[0]}
                </p>
              )}
            </div>
          </div>
          <div className="registry-input-div top-margin">
            <label htmlFor="title-item">
              Document File <span className="required-asterisk">*</span>
            </label>
            <div
              className="d-flex align-items-center justify-content-between mb-4 p-1 px-2"
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #afafaf",
                borderRadius: "4px",
                outline: "none",
                color: "#7e7e7e",
              }}
            >
              <input
                type="file"
                id="document"
                name="document"
                className="d-none"
                onChange={handleFileChange}
                accept="image/jpeg, image/png, application/pdf"
                ref={documentFileRef}
              />
              <button
                className="bg-white py-2 px-4 border-0 rounded-pill"
                style={{
                  cursor: "pointer",
                  boxShadow: "0px 2px 4px #0000001a",
                }}
                type="button"
                onClick={handleButtonClick}
              >
                {documentFile ? documentFile.name : "Choose File"}
              </button>
            </div>
            {fieldErrors.document && (
              <p className="joi-error-message">{fieldErrors.document[0]}</p>
            )}
          </div>
        </div>

        <button
          type="submit"
          className="login-btn"
          disabled={disabled}
          style={{
            cursor: disabled ? "default" : "pointer",
            opacity: disabled ? "0.7" : "1",
          }}
        >
          {loading ? (
            <div className="d-flex align-items-center justify-content-center">
              <div class="registry-loader"></div>
            </div>
          ) : (
            <span>Continue</span>
          )}
        </button>
      </form>
    </>
  );
};

export default RegistryForm;
