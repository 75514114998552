import React from "react";
import "../assets/css/footer.css";

const HalfFooter = () => {
  return (
    <footer className="footer">
      <div className="footer-contact">
        <p>Private Policy | +1-800-236-8322</p>
        <p>https://titleregistry.io</p>
      </div>
    </footer>
  );
};

export default HalfFooter;
