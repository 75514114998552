import React, { useEffect, useRef, useState } from "react";
import { MdDeleteOutline, MdBlockFlipped, MdEdit } from "react-icons/md";
import { CgUnblock } from "react-icons/cg";
import { useLocation } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import PaginatedTable from "./table";
import EditUserModal from "./editUser.js";
import AddUser from "./addUser.js";
import Alert from "./alert.js";
import "../assets/css/userTable.css";
const serverBaseUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

const AdminTables = () => {
  const limit = 5;
  const pdfRef = useRef(null);
  const location = useLocation();
  const [users, setUsers] = useState([]);
  const [registries, setRegistries] = useState([]);
  const [alertData, setAlertData] = useState(null);
  const [success, setSuccess] = useState(null);
  const [userOffset, setUserOffset] = useState(0);
  const [registryOffset, setRegistryOffset] = useState(0);
  const [totalRegistries, setTotalRegistries] = useState(null);
  const [showPopover, setShowPopover] = useState(null);
  const [totalUsers, setTotalUsers] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [disabled, setDisabled] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const token = localStorage.getItem("token");

  const getUsers = async (offset, limit) => {
    try {
      const response = await fetch(
        `${serverBaseUrl}/admin/users?offset=${offset}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      const responseData = await response.json();
      if (response.status === 200) {
        setUsers(responseData.data.users);
        setTotalUsers(responseData.data.usersCount);
      } else {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(null), 3000);
      }
    } catch (error) {
      setAlertData(error.message || "An error occurred");
      setTimeout(() => setAlertData(null), 3000);
    }
  };

  const blockUser = async (email) => {
    try {
      const response = await fetch(`${serverBaseUrl}/admin/block-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          email,
        }),
      });
      const responseData = await response.json();
      if (response.status === 200) {
        setSuccess(responseData.message);
        setTimeout(() => setSuccess(null), 3000);
      } else {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(null), 3000);
      }
    } catch (error) {
      setAlertData(error.message || "An error occurred");
      setTimeout(() => setAlertData(null), 3000);
    } finally {
      await getUsers(userOffset, limit);
    }
  };

  const unblockUser = async (email) => {
    try {
      const response = await fetch(`${serverBaseUrl}/admin/unblock-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          email,
        }),
      });
      const responseData = await response.json();
      if (response.status === 200) {
        setSuccess(responseData.message);
        setTimeout(() => setSuccess(null), 3000);
      } else {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(null), 3000);
      }
    } catch (error) {
      setAlertData(error.message || "An error occurred");
      setTimeout(() => setAlertData(null), 3000);
    } finally {
      await getUsers(userOffset, limit);
    }
  };

  const deleteUser = async (email) => {
    try {
      const response = await fetch(`${serverBaseUrl}/admin/user`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          email,
        }),
      });
      const responseData = await response.json();
      if (response.status === 200) {
        setSuccess(responseData.message);
        setTimeout(() => setSuccess(null), 3000);
      } else {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(null), 3000);
      }
    } catch (error) {
      setAlertData(error.message || "An error occurred");
      setTimeout(() => setAlertData(null), 3000);
    } finally {
      await getUsers(userOffset, limit);
      setShowPopover(null);
    }
  };

  const getRegistries = async (offset, limit) => {
    try {
      const response = await fetch(
        `${serverBaseUrl}/admin/registries?offset=${offset}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      const responseData = await response.json();
      if (response.status === 200) {
        setRegistries(responseData.data.registries);
        setTotalRegistries(responseData.data.totalRegistries);
      } else {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(null), 3000);
      }
    } catch (error) {
      setAlertData(error.message || "An error occurred");
      setTimeout(() => setAlertData(null), 3000);
    }
  };

  const deleteRegistry = async (id) => {
    try {
      const response = await fetch(`${serverBaseUrl}/admin/registry`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          id,
        }),
      });
      const responseData = await response.json();
      if (response.status === 200) {
        setSuccess(responseData.message);
        setTimeout(() => setSuccess(null), 3000);
      } else {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(null), 3000);
      }
    } catch (error) {
      setAlertData(error.message || "An error occurred");
      setTimeout(() => setAlertData(null), 3000);
    } finally {
      await getRegistries(registryOffset, limit);
      setShowPopover(false);
    }
  };

  const handleAddUser = () => {
    setFieldErrors({});
    setAddModalOpen(true);
  };

  const handleCreateUser = async (updatedUser) => {
    const firstName = updatedUser.first_name;
    const lastName = updatedUser.last_name;
    const email = updatedUser.email;
    const dateOfBirth = updatedUser.date_of_birth;
    const phoneNumber = updatedUser.phone_number;
    try {
      setFieldErrors({});
      setDisabled(true);
      const response = await fetch(`${serverBaseUrl}/admin/user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          phoneNumber,
          dateOfBirth,
        }),
      });
      const responseData = await response.json();
      if (response.status === 201) {
        setSuccess(responseData.message);
        setTimeout(() => setSuccess(null), 3000);
        await getUsers(userOffset, limit);
        setAddModalOpen(false);
      } else if (response.status === 400) {
        const error = typeof responseData.error;
        if (error === "object") {
          setFieldErrors(responseData.error);
        } else {
          setAlertData(responseData.message || "An error occurred");
          setTimeout(() => setAlertData(false), 3000);
        }
      } else {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(null), 3000);
      }
    } catch (error) {
      setAlertData(error.message || "An error occurred");
      setTimeout(() => setAlertData(null), 3000);
    } finally {
      setDisabled(false);
    }
  };

  const handleEditUser = (user) => {
    setFieldErrors({});
    setShowPopover(false);
    setEditingUser(user);
    setIsModalOpen(true);
  };

  const handleSaveUser = async (updatedUser) => {
    const firstName = updatedUser.first_name;
    const lastName = updatedUser.last_name;
    const email = updatedUser.email;
    const dateOfBirth = updatedUser.date_of_birth;
    const phoneNumber = updatedUser.phone_number;
    try {
      setFieldErrors({});
      setDisabled(true);
      const response = await fetch(`${serverBaseUrl}/admin/user`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          phoneNumber,
          dateOfBirth,
        }),
      });
      const responseData = await response.json();
      if (response.status === 200) {
        setSuccess(responseData.message);
        setTimeout(() => setSuccess(null), 3000);
        await getUsers(userOffset, limit);
        setIsModalOpen(false);
      } else if (response.status === 400) {
        const error = typeof responseData.error;
        if (error === "object") {
          setFieldErrors(responseData.error);
        } else {
          setAlertData(responseData.message || "An error occurred");
          setTimeout(() => setAlertData(false), 3000);
        }
      } else {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(null), 3000);
      }
    } catch (error) {
      setAlertData(error.message || "An error occurred");
      setTimeout(() => setAlertData(null), 3000);
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (location.pathname === "/admin/dashboard") {
      getUsers(userOffset, limit);
    } else if (location.pathname === "/admin/registries") {
      getRegistries(registryOffset, limit);
    }
  }, [userOffset, registryOffset]);

  const handleUserPageChange = (offset, limit) => {
    setUserOffset(offset);
    getUsers(offset, limit);
  };

  const handleRegistryPageChange = (offset, limit) => {
    setRegistryOffset(offset);
    getRegistries(offset, limit);
  };

  const togglePopover = (index) => {
    if (showPopover === index) {
      setShowPopover(null);
    } else {
      setShowPopover(index);
    }
  };

  const closeModal = () => {
    setPdfModalOpen(false);
    setSelectedPdf(null);
  };

  const handleClickOutside = (event) => {
    if (pdfRef.current && !pdfRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    if (pdfModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [pdfModalOpen]);

  return (
    <>
      {alertData && <Alert message={alertData} type={"error"} />}
      {success && <Alert message={success} type={"success"} />}
      {location.pathname === "/admin/dashboard" ? (
        <>
          <div className="table-heading">
            <h2>Users</h2>
            <button onClick={() => handleAddUser()}>
              <MdOutlineAdd size={22} />
            </button>
          </div>
          <PaginatedTable
            data={users}
            headers={[
              "Name",
              "Email",
              "Date of Birth",
              "Phone Number",
              "Status",
              "Actions",
            ]}
            totalData={totalUsers}
            limit={limit}
            onPageChange={handleUserPageChange}
            renderRow={(user, index) => (
              <tr key={index} className="table-row">
                <td>{user.first_name + " " + user.last_name}</td>
                <td className="text-muted">{user.email}</td>
                <td>{user.date_of_birth}</td>
                <td>{user.phone_number}</td>
                <td>{user.user_status}</td>
                <td className="actions-td">
                  {user.user_status !== "deleted" && (
                    <span>
                      <OverlayTrigger
                        trigger="click"
                        show={showPopover === `edit-${index}`}
                        onToggle={() => togglePopover(`edit-${index}`)}
                        placement="top"
                        overlay={
                          <Popover id={`popover-edit-${index}`}>
                            <Popover.Header as="h2">
                              <strong>Edit</strong>{" "}
                              <span>
                                {user.first_name + " " + user.last_name}
                              </span>{" "}
                              ?
                            </Popover.Header>
                            <Popover.Body>
                              <div className="d-flex justify-content-end mt-2">
                                <Button
                                  size="sm"
                                  variant="primary"
                                  className="me-2"
                                  onClick={() => handleEditUser(user)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  size="sm"
                                  className="text-black bg-white"
                                  variant="secondary"
                                  onClick={() => togglePopover(`edit-${index}`)}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <button
                          className="icon-btn"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit"
                          data-original-title="Edit"
                        >
                          <MdEdit size={22} color="grey" />
                        </button>
                      </OverlayTrigger>
                      {user.user_status === "blocked" ? (
                        <OverlayTrigger
                          trigger="click"
                          show={showPopover === `unblocked-${index}`}
                          onToggle={() => togglePopover(`unblocked-${index}`)}
                          placement="top"
                          overlay={
                            <Popover id={`popover-unblock-${index}`}>
                              <Popover.Header as="h2">
                                <strong>Unblock</strong>{" "}
                                <span>
                                  {user.first_name + " " + user.last_name}
                                </span>{" "}
                                ?
                              </Popover.Header>
                              <Popover.Body>
                                <div className="d-flex justify-content-end mt-2">
                                  <Button
                                    size="sm"
                                    variant="success"
                                    className="me-2"
                                    onClick={() => {
                                      togglePopover(`unblocked-${index}`);
                                      setTimeout(async () => {
                                        unblockUser(user.email);
                                      }, 1000);
                                    }}
                                  >
                                    Unblock
                                  </Button>
                                  <Button
                                    size="sm"
                                    className="text-black bg-white"
                                    variant="secondary"
                                    onClick={() =>
                                      togglePopover(`unblocked-${index}`)
                                    }
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <button
                            className="icon-btn"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Unblocked"
                            data-original-title="Unblocked"
                          >
                            <CgUnblock size={22} color="green" />
                          </button>
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          trigger="click"
                          show={showPopover === `blocked-${index}`}
                          onToggle={() => togglePopover(`blocked-${index}`)}
                          placement="top"
                          overlay={
                            <Popover id={`popover-block-${index}`}>
                              <Popover.Header as="h2">
                                <strong>Block</strong>{" "}
                                <span>
                                  {user.first_name + " " + user.last_name}
                                </span>{" "}
                                ?
                              </Popover.Header>
                              <Popover.Body>
                                <div className="d-flex justify-content-end mt-2">
                                  <Button
                                    size="sm"
                                    variant="danger"
                                    className="me-2"
                                    onClick={() => {
                                      setTimeout(() => {
                                        togglePopover(`blocked-${index}`);
                                        blockUser(user.email);
                                      }, 1000);
                                    }}
                                  >
                                    Block
                                  </Button>
                                  <Button
                                    size="sm"
                                    className="text-black bg-white"
                                    variant="secondary"
                                    onClick={() =>
                                      togglePopover(`blocked-${index}`)
                                    }
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <button
                            className="icon-btn"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="blocked"
                            data-original-title="blocked"
                          >
                            <MdBlockFlipped size={22} color="red" />
                          </button>
                        </OverlayTrigger>
                      )}
                      <OverlayTrigger
                        trigger="click"
                        show={showPopover === `delete-${index}`}
                        onToggle={() => togglePopover(`delete-${index}`)}
                        placement="top"
                        overlay={
                          <Popover id={`popover-delete-${index}`}>
                            <Popover.Header as="h2">
                              <strong>Delete</strong>{" "}
                              <span>
                                {user.first_name + " " + user.last_name}
                              </span>{" "}
                              ?
                            </Popover.Header>
                            <Popover.Body>
                              Once a user is deleted, all his data and
                              certificates are removed
                              <div className="d-flex justify-content-end mt-2">
                                <Button
                                  size="sm"
                                  variant="danger"
                                  className="me-2"
                                  onClick={() => deleteUser(user.email)}
                                >
                                  Delete
                                </Button>
                                <Button
                                  size="sm"
                                  className="text-black bg-white"
                                  variant="secondary"
                                  onClick={() =>
                                    togglePopover(`delete-${index}`)
                                  }
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <button
                          className="icon-btn"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete"
                          data-original-title="Delete"
                        >
                          <MdDeleteOutline size={22} color="red" />
                        </button>
                      </OverlayTrigger>
                    </span>
                  )}
                </td>
              </tr>
            )}
          />
        </>
      ) : location.pathname === "/admin/registries" ? (
        <PaginatedTable
          data={registries}
          headers={[
            "Title Item",
            "Owner's Email",
            "Material Type",
            "Identification",
            "Status",
            "Actions",
          ]}
          limit={limit}
          totalData={totalRegistries}
          onPageChange={handleRegistryPageChange}
          renderRow={(registry, index) => (
            <tr key={index} className="table-row registry-table-rows">
              <td>{registry.title_item}</td>
              <td>{registry.email}</td>
              <td>{registry.material_type}</td>
              <td>{registry.identification}</td>
              <td>{registry.registry_status}</td>
              <td>
                {registry.registry_status !== "deleted" && (
                  <OverlayTrigger
                    trigger="click"
                    show={showPopover === `delete-${index}`}
                    onToggle={() => togglePopover(`delete-${index}`)}
                    placement="top"
                    overlay={
                      <Popover id={`popover-delete-${index}`}>
                        <Popover.Header as="h2">
                          <strong>Delete</strong>{" "}
                          <span>{registry.title_item}</span> ?
                        </Popover.Header>
                        <Popover.Body>
                          Are you sure you want to delete this registry? This
                          action cannot be undone.
                          <div className="d-flex justify-content-end mt-2">
                            <Button
                              size="sm"
                              variant="danger"
                              className="me-2"
                              onClick={() => deleteRegistry(registry.id)}
                            >
                              Delete
                            </Button>
                            <Button
                              size="sm"
                              className="text-black bg-white"
                              variant="secondary"
                              onClick={() => togglePopover(`delete-${index}`)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <button
                      className="icon-btn"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Delete"
                      data-original-title="Delete"
                    >
                      <MdDeleteOutline size={22} color="red" />
                    </button>
                  </OverlayTrigger>
                )}
              </td>
            </tr>
          )}
        />
      ) : null}

      {isModalOpen && (
        <EditUserModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          user={editingUser}
          onSave={handleSaveUser}
          disabled={disabled}
          fieldErrors={fieldErrors}
        />
      )}

      {addModalOpen && (
        <AddUser
          isOpen={addModalOpen}
          onClose={() => setAddModalOpen(false)}
          onSave={handleCreateUser}
          disabled={disabled}
          fieldErrors={fieldErrors}
        />
      )}

      {selectedPdf && (
        <div className="modal-overlay">
          <div
            className="model-inside-div"
            style={{
              width: "50%",
              height: "70%",
            }}
          >
            <iframe
              src={selectedPdf}
              width="100%"
              height="100%"
              className="show-pdf"
              ref={pdfRef}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AdminTables;
