import React, { useEffect, useRef, useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Alert from "./alert";
const serverBaseUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

export function Signup({ setFormType }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dateOfBirth, setdateOfBirth] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [alertData, setAlertData] = useState(false);
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const dateOfBirthRef = useRef();
  const phoneNumberRef = useRef();

  const refs = {
    firstName: firstNameRef,
    lastName: lastNameRef,
    email: emailRef,
    password: passwordRef,
    dateOfBirth: dateOfBirthRef,
    phoneNumber: phoneNumberRef,
  };

  useEffect(() => {
    const errorFields = Object.keys(fieldErrors);
    if (errorFields.length > 0) {
      const firstErrorField = errorFields[0];
      if (refs[firstErrorField] && refs[firstErrorField].current) {
        refs[firstErrorField].current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [fieldErrors]);

  const signup = async (e) => {
    e.preventDefault();
    try {
      if (
        !firstName ||
        !lastName ||
        !email ||
        !password ||
        !dateOfBirth ||
        !phoneNumber
      ) {
        setAlertData("Please fill all fields");
        setTimeout(() => setAlertData(false), 3000);
        return;
      }
      setDisabled(true);
      setFieldErrors({});
      const response = await fetch(`${serverBaseUrl}/user/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          password,
          dateOfBirth,
          phoneNumber,
        }),
      });
      const responseData = await response.json();
      if (response.status === 201) {
        setSuccess(responseData.message);
        setTimeout(() => {
          setSuccess(false);
          setFormType("login");
        }, 3000);
        return;
      } else if (response.status === 400) {
        const error = typeof responseData.error;
        if (error === "object") {
          setFieldErrors(responseData.error);
        } else {
          setAlertData(responseData.message || "An error occurred");
          setTimeout(() => setAlertData(false), 3000);
        }
      } else {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(false), 3000);
      }
    } catch (error) {
      setAlertData(error);
      setTimeout(() => setAlertData(false), 3000);
      return;
    } finally {
      setDisabled(false);
      return;
    }
  };

  const getYesterdayDate = () => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    return today.toISOString().split("T")[0];
  };

  return (
    <>
      {alertData && <Alert message={alertData} type={"error"} />}
      {success && <Alert message={success} type={"success"} />}
      <form onSubmit={signup} className="signup-form">
        <h3>Title Registry</h3>
        <h4>Create Account</h4>
        <div className="fields">
          <div className="input-div">
            <label htmlFor="first-name">
              First Name <span className="required-asterisk">*</span>
            </label>
            <input
              ref={firstNameRef}
              id="first-name"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First name"
              required
            />
            {fieldErrors.firstName && (
              <p className="joi-error-message">{fieldErrors.firstName[0]}</p>
            )}
          </div>
          <div className="input-div">
            <label htmlFor="last-name">
              Last Name <span className="required-asterisk">*</span>
            </label>
            <input
              ref={lastNameRef}
              id="last-name"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last name"
              required
            />
            {fieldErrors.lastName && (
              <p className="joi-error-message">{fieldErrors.lastName[0]}</p>
            )}
          </div>
          <div className="input-div">
            <label htmlFor="dob">
              DOB <span className="required-asterisk">*</span>
            </label>
            <input
              ref={dateOfBirthRef}
              id="dob"
              type="date"
              value={dateOfBirth}
              onChange={(e) => setdateOfBirth(e.target.value)}
              placeholder="Date of Birth"
              max={getYesterdayDate()}
              required
            />
            {fieldErrors.dateOfBirth && (
              <p className="joi-error-message">{fieldErrors.dateOfBirth[0]}</p>
            )}
          </div>
          <div className="input-div">
            <label htmlFor="phone-number">
              Phone Number <span className="required-asterisk">*</span>
            </label>
            <input
              ref={phoneNumberRef}
              id="phone-number"
              type="text"
              value={phoneNumber}
              onChange={(e) => setphoneNumber(e.target.value)}
              placeholder="+14175555470"
              maxLength="12"
              required
            />
            {fieldErrors.phoneNumber && (
              <p className="joi-error-message">{fieldErrors.phoneNumber[0]}</p>
            )}
          </div>
          <div className="input-div">
            <label htmlFor="email">
              Email <span className="required-asterisk">*</span>
            </label>
            <input
              ref={emailRef}
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="example@email.com"
              required
            />
            {fieldErrors.email && (
              <p className="joi-error-message">{fieldErrors.email[0]}</p>
            )}
          </div>
          <div className="input-div">
            <label htmlFor="password">
              Password <span className="required-asterisk">*</span>
            </label>
            <div className="password-input">
              <input
                ref={passwordRef}
                id="password"
                className="input"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="********"
                required
              />
              <div className="icon-div">
                {showPassword ? (
                  <BsEye
                    size={18}
                    color="#8d8d8d"
                    onClick={togglePasswordVisibility}
                    className="eye-icon"
                  />
                ) : (
                  <BsEyeSlash
                    size={18}
                    color="#8d8d8d"
                    onClick={togglePasswordVisibility}
                    className="eye-icon"
                  />
                )}
              </div>
            </div>
          </div>
          {fieldErrors.password && (
            <p className="joi-error-message">{fieldErrors.password[0]}</p>
          )}
        </div>
        <button type="submit" className="login-btn" disabled={disabled}>
          Create Account
        </button>
      </form>
    </>
  );
}
