import React, { useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Sidebar from "../component/sidebar.js";
import Header from "../component/header.js";
import Alert from "../component/alert.js";

const serverBaseUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

function ProfileForm() {
  const admin = JSON.parse(localStorage.getItem("admin"));
  const token = localStorage.getItem("token");
  const [firstName, setFirstName] = useState(admin.firstName);
  const [lastName, setLastName] = useState(admin.lastName);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currentPasswordShow, setCurrentPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [alertData, setAlertData] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const newPasswordRef = useRef();
  const currentPasswordRef = useRef();

  const refs = {
    firstName: firstNameRef,
    lastName: lastNameRef,
    newPassword: newPasswordRef,
    currentPassword: currentPasswordRef,
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    try {
      if (!firstName || !lastName) {
        setAlertData("Please insert all fields");
        setTimeout(() => setAlertData(false), 3000);
        return;
      }
      setDisabled(true);
      setFieldErrors({});
      const response = await fetch(`${serverBaseUrl}/admin/profile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ firstName, lastName }),
      });
      const responseData = await response.json();
      if (response.status === 200) {
        const admin = responseData.data;
        localStorage.setItem("admin", JSON.stringify(admin));
        setSuccess(responseData.message);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
        return;
      } else if (response.status === 400) {
        const error = typeof responseData.error;
        if (error === "object") {
          setFieldErrors(responseData.error);
          const errorFields = Object.keys(fieldErrors);
          if (errorFields.length > 0) {
            const firstErrorField = errorFields[0];
            if (refs[firstErrorField] && refs[firstErrorField].current) {
              refs[firstErrorField].current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
              });
            }
          }
        } else {
          setAlertData(responseData.message || "An error occurred");
          setTimeout(() => setAlertData(false), 3000);
        }
      } else {
        setAlertData(responseData?.message || "An error occurred");
        setTimeout(() => setAlertData(false), 3000);
        return;
      }
    } catch (error) {
      setAlertData(error.message);
      setTimeout(() => setAlertData(false), 3000);
      return;
    } finally {
      setDisabled(false);
      return;
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    try {
      if (!currentPassword || !newPassword) {
        setAlertData("Please insert all fields");
        setTimeout(() => setAlertData(false), 3000);
        return;
      }
      setDisabled(true);
      setFieldErrors({});
      const response = await fetch(`${serverBaseUrl}/admin/password`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ currentPassword, newPassword }),
      });
      const responseData = await response.json();
      if (response.status === 200) {
        setCurrentPassword("");
        setNewPassword("");
        setNewPasswordShow(false);
        setCurrentPasswordShow(false);
        setSuccess(responseData.message);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
        return;
      } else if (response.status === 400) {
        const error = typeof responseData.error;
        if (error === "object") {
          setFieldErrors(responseData.error);
          const errorFields = Object.keys(fieldErrors);
          if (errorFields.length > 0) {
            const firstErrorField = errorFields[0];
            if (refs[firstErrorField] && refs[firstErrorField].current) {
              refs[firstErrorField].current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
              });
            }
          }
        } else {
          setAlertData(responseData.message || "An error occurred");
          setTimeout(() => setAlertData(false), 3000);
        }
      } else {
        setAlertData(responseData?.message || "An error occurred");
        setTimeout(() => setAlertData(false), 3000);
        return;
      }
    } catch (error) {
      setAlertData(error.message);
      setTimeout(() => setAlertData(false), 3000);
      return;
    } finally {
      setDisabled(false);
      return;
    }
  };

  return (
    <>
      {alertData && <Alert message={alertData} type={"error"} />}
      {success && <Alert message={success} type={"success"} />}
      <div className="admin-profile-form">
        <form onSubmit={updateProfile}>
          <p className="admin-login-heading">Admin Profile</p>
          <div className="modal-input-div">
            <label>
              First Name <span className="required-asterisk">*</span>
            </label>
            <input
              ref={firstNameRef}
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          {fieldErrors.firstName && (
            <p className="joi-error-message">{fieldErrors.firstName[0]}</p>
          )}
          <div className="modal-input-div">
            <label>
              Last Name <span className="required-asterisk">*</span>
            </label>
            <input
              ref={lastNameRef}
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          {fieldErrors.lastName && (
            <p className="joi-error-message">{fieldErrors.lastName[0]}</p>
          )}
          <div className="modal-input-div">
            <label>
              Email <span className="required-asterisk">*</span>
            </label>
            <input type="email" value={admin.email} disabled required />
          </div>
          <p className="btn-para">
            <button type="submit" className="login-btn" disabled={disabled}>
              Update profile
            </button>
          </p>
        </form>
        <Accordion defaultActiveKey="0" className="accordion">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Password</Accordion.Header>
            <Accordion.Body>
              <div className="input-div">
                <label htmlFor="newPassword">
                  Current Password <span className="required-asterisk">*</span>
                </label>
                <div className="password-input">
                  <input
                    ref={currentPasswordRef}
                    id="newPassword"
                    className="input"
                    type={currentPasswordShow ? "text" : "password"}
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    placeholder="********"
                  />
                  <button
                    type="button"
                    onClick={() => setCurrentPasswordShow(!currentPasswordShow)}
                    className="icon-div reset-icon-div"
                  >
                    {currentPasswordShow ? (
                      <BsFillEyeFill
                        size={18}
                        color="#8d8d8d"
                        className="eye-icon"
                      />
                    ) : (
                      <BsFillEyeSlashFill
                        size={18}
                        color="#8d8d8d"
                        className="eye-icon"
                      />
                    )}
                  </button>
                </div>
              </div>
              {fieldErrors.currentPassword && (
                <p className="joi-error-message">
                  {fieldErrors.currentPassword[0]}
                </p>
              )}
              <div className="input-div">
                <label htmlFor="confirmPassword">
                  New Password <span className="required-asterisk">*</span>
                </label>
                <div className="password-input">
                  <input
                    ref={newPasswordRef}
                    id="confirmPassword"
                    className="input"
                    type={newPasswordShow ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="********"
                  />
                  <button
                    type="button"
                    onClick={() => setNewPasswordShow(!newPasswordShow)}
                    className="icon-div reset-icon-div"
                  >
                    {newPasswordShow ? (
                      <BsFillEyeFill
                        size={18}
                        color="#8d8d8d"
                        className="eye-icon"
                      />
                    ) : (
                      <BsFillEyeSlashFill
                        size={18}
                        color="#8d8d8d"
                        className="eye-icon"
                      />
                    )}
                  </button>
                </div>
              </div>
              {fieldErrors.newPassword && (
                <p className="joi-error-message">
                  {fieldErrors.newPassword[0]}
                </p>
              )}
              <button
                type="button"
                className="login-btn"
                onClick={updatePassword}
                disabled={disabled}
              >
                Update password
              </button>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}

function AdminProfile() {
  return (
    <div className="dashboard">
      <Sidebar />
      <div className="main-dashboard">
        <div className="header-div">
          <Header isAdmin={true} />
        </div>
        <div className="table-div">
          <ProfileForm />
        </div>
      </div>
    </div>
  );
}

export default AdminProfile;
